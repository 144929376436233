import { Controller } from "stimulus"
import * as $ from 'jquery'
window.$ = window.jQuery = $

export default class extends Controller {
  sendData(_event){
    var input = $('.sign-up-component .input-block input')[0]
    if (!input.checkValidity()){ return input.reportValidity() }    
    $.ajax
    ({
      url:      this.endpoint(),
      type:     'post',
      dataType: 'json',
      data:    this.bodyData(),
      error: response => {
        console.log('error', response)
      },
      complete: () => {
        this.afterSend()
      }
    })
  }

  email(){
    return $('.sign-up-component .input-block input').val()
  }

  afterSend(){
    $('.sign-up-component .input-block').empty()
    $('.sign-up-component .suffix-content').empty()
    $('.sign-up-component .suffix').prepend(`<p class="m-0 text-grey-500">${this.successMessage()}</p>`)
  }

  successMessage(){
    return 'Thanks for signing up!'
  }

  bodyData(){
    return { registration: 
      {
        email: this.email, 
        request_url: window.location.href,
        content: this.contentPayload()
      }
    }
  }

  endpoint(){
    return '/api/registrations'
  }

  // For content only
  // --------------------------------------------------------
  contentPayload(){
    return {
      content_type: this.contentTypes(),
      language_code: this.languageCode(),
      category_slug: this.getHiddenCategoryNameById("registrations_primary_category_name"),
      subcategory_slug: this.getHiddenCategoryNameById("registrations_primary_subcategory_name")
    }
  }

  languageCode(){
    const languageCode = $("input[type='hidden']#registrations_post_languages").val();
    return languageCode && languageCode !== 'en' ? languageCode : undefined;
  }

  contentTypes(){
    const contentType = $("input[type='hidden']#registrations_content_type").val();
    if (contentType) { return contentType }
  }

  getHiddenCategoryNameById(id){
    const slug = $(`input[type='hidden']#${id}`).val();
    if (slug) { return slug } 
  }
  // --------------------------------------------------------
}
